import React from "react";
import Hero from "../components/common/hero";
import useTranslations from "../components/useTranslations";
import HelmetHeader from "../components/helmetHeader";
import CtaSection from "../components/ctaSection";
import { graphql } from "gatsby";
import LocalizedLink from "../components/localizedLink";
import { HiChevronDoubleRight } from "react-icons/all";

const ContactUs = ({ location, data: { page, items } }) => {
  const { lang } = useTranslations();
  return (
    <>
      <HelmetHeader
        location={location}
        lang={lang}
        title={page.seo.metaTitle}
        metas={[{ name: "description", content: page.seo.metaDescription }]}
      />
      <Hero title={page.header?.title} subtitle={page.header?.subtitle} />
      <div className="w-full bg-white">
        <div className="relative max-w-5xl mx-auto">
          <div className="mt-20 mb-12">
            <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:grid-cols-3">
              {items.nodes.map((item) => (
                <LocalizedLink
                  className="rounded-lg bg-gray-50 pb-2 px-6 hover:shadow-lg cursor-pointer"
                  key={item.order}
                  to={item.link?.href}
                >
                  <div className="-mt-4 mb-4 flex flex-col justify-between h-full">
                    <div className="flex flex-col mb-4">
                      <div className="flex justify-start">
                        <div className="inline-flex text-white items-center justify-center rounded-md bg-cyan-600 p-3 shadow-lg">
                          <div
                            dangerouslySetInnerHTML={{ __html: item.icon }}
                          />
                        </div>
                      </div>

                      <h3 className="mt-6 text-lg font-medium tracking-tight text-gray-900">
                        {item.title}
                      </h3>
                      <p className="mt-5 text-base text-gray-500">
                        {item.description}
                      </p>
                    </div>
                    <div className="flex justify-end">
                      <HiChevronDoubleRight className="w-5 h-5 text-cyan-700" />
                    </div>
                  </div>
                </LocalizedLink>
              ))}
            </div>
          </div>
        </div>
        <CtaSection />
      </div>
    </>
  );
};

export const query = graphql`
  query StrapiContactUsPage($locale: String) {
    page: strapiContactUsPage(locale: { eq: $locale }) {
      header {
        subtitle
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    items: allStrapiContactItem(
      sort: { fields: order, order: ASC }
      filter: { locale: { eq: $locale } }
    ) {
      nodes {
        title
        description
        order
        icon
        link {
          href
          target
        }
      }
    }
  }
`;

export default ContactUs;
